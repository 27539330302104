<script>
import { mapActions, mapState } from "vuex";
import List from "./pages/listBusinessPlans";
import CreateOrEdit from "./pages/createOrEditBusinessPlan";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "BusinessPlansIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("businessplans", {
      contents: state => state.businessPlans
    })
  },

  methods: {
    ...mapActions("businessplans", {
      createBusinessPlan: "createBusinessPlan",
      updateBusinessPlan: "updateBusinessPlan",
      getAvailableBasePlans: "getAvailableBasePlans"
    }),
    ...mapActions("nodeTypes", {
      getNodeTypes: "getNodeTypes"
    }),
    ...mapActions("appfeatures", {
      getAppFeatures: "getAppFeatures"
    }),
    ...mapActions("ttspackages", {
      getTtsPackages: "getTtsPackages"
    }),
    ...mapActions("asrlanguagepack", {
      getAsrLanguagePackages: "getAsrLanguagePackages"
    }),
    initContent() {
      return {
        bp_name: "",
        base_plan_id: null,
        plan_type: "",
        min_unit: "",
        price_per_unit: 1,
        bp_status: true,
        nodes: [],
        tts_packages: [],
        features: [],
        asr_packages: [],
        can_remove_base: true,
        is_in_use_as_base: false,
        is_billable: true
      };
    }
  },
  mounted() {
    this.contentType = "Business Plan";
    this.primaryKey = "bp_id";
    this.scope = "sys";
    this.getNodeTypes({ plan_type: "IVA" });
    this.getAppFeatures();
    this.getTtsPackages();
    this.getAsrLanguagePackages();
    //this.getAvailableBasePlans();
  }
};
</script>

<style lang="scss" scoped></style>
