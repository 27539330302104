<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <div class="titles">{{ __("Plan Name") }}</div>
      <div class="details">{{ contentFormInfo.bp_name }}</div>
      <div class="titles">{{ __("Plan Type") }}</div>
      <div class="details">{{ contentFormInfo.plan_type }}</div>
      <div class="titles">{{ __("Minimum Units To Sell") }}</div>
      <div class="details">{{ contentFormInfo.min_unit }}</div>
      <div class="titles">{{ __("Price Per Unit") }}</div>
      <div class="details">{{ contentFormInfo.price_per_unit }}</div>
      <div v-if="showCompare" style="max-height: 60vh">
        <el-dialog
          :visible.sync="showCompare"
          width="65%"
          show-close
          style="margin-top: -12vh!important;"
          :before-close="handleCompareClose"
        >
          <plan-compare :content-form-info="contentFormInfo"></plan-compare>
        </el-dialog>
      </div>
      <div v-if="showTree" style="max-height: 60vh">
        <el-dialog
          :visible.sync="showTree"
          width="65%"
          show-close
          style="margin-top: -12vh!important;"
          :before-close="handleTreeViewClose"
        >
          <plan-tree :bp_id="contentFormInfo.bp_id"></plan-tree>
        </el-dialog>
      </div>
      <div style="display: flex; padding-top: 20px">
        <el-dropdown @command="handleAction" class="campaignActionDropDown">
          <el-button type="primary">
            {{ __("Actions") }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="!canWrite"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="details"
        style="padding-top: 20px"
        v-if="contentFormInfo.series_data.length > 0"
      >
        <plan-hierarchy :series-data="contentFormInfo.series_data">
        </plan-hierarchy>
      </div>

      <!--      <el-button @click="handleEdit" class="editBtn" v-show="this.canWrite()"
        >Edit</el-button
      >
      <el-button
        @click="handleDelete(contentFormInfo)"
        class="deleteBtn"
        v-show="this.canWrite()"
        >Delete
      </el-button>-->
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="plan-form-dialog">
        <el-row type="flex">
          <el-col :span="16" :offset="4">
            <page-header :title="__('Business Plan')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <div>
            <el-form
              ref="contentForm"
              :rules="rules"
              :model="contentForm"
              label-position="top"
              size="large"
            >
              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item
                    :label="__('Business Plan Name')"
                    prop="bp_name"
                  >
                    <el-input v-model="contentForm.bp_name"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item :label="__('Plan Type')" prop="plan_type">
                    <el-select
                      v-model="contentForm.plan_type"
                      style="width: 100%"
                      @change="loadFeatures"
                      filterable
                      default-first-option
                    >
                      <el-option
                        v-for="type in this.plan_type"
                        :key="type"
                        :label="type"
                        :value="type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item
                    :label="__('Biometric Type')"
                    prop="biometric_type"
                    v-show="contentForm.plan_type === 'Biometrics'"
                  >
                    <el-radio-group
                      v-model="contentForm.biometric_type"
                      @change="loadFeatures('Biometrics')"
                    >
                      <el-radio label="Verification">{{
                        __("Transaction")
                      }}</el-radio>
                      <el-radio label="Enrolment">{{
                        __("Enrolment")
                      }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item
                    v-show="
                      (contentFormInfo.base_plan_id !== null &&
                        this.formAction === 'edit') ||
                        (contentFormInfo.base_plan_id === null &&
                          this.formAction === 'add' &&
                          contentForm.plan_type === 'IVA')
                    "
                    :label="__('Base Plan')"
                    prop="base_plan_id"
                  >
                    <div v-if="!contentFormInfo.can_remove_base" class="info">
                      <div class="warning-exclamation" />
                      <div style="margin-left: 5px; display: flex">
                        <div>
                          <!-- eslint-disable-next-line -->
                          {{ __("Base plan cannot be removed as this plan is extended in other business plans.") }}
                        </div>
                      </div>
                    </div>
                    <el-select
                      filterable
                      default-first-option
                      v-model="contentForm.base_plan_id"
                      v-loading="isLoading || isFetching"
                      :disabled="!contentFormInfo.can_remove_base"
                      style="width: 100%"
                      @change="setBasePlan"
                      @clear="clearBasePlan"
                      clearable
                    >
                      <el-option
                        v-for="businessPlan in this.basePlans"
                        :key="businessPlan.bp_id"
                        :label="businessPlan.bp_name"
                        :value="businessPlan.bp_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-scrollbar :native="false">
                    <div
                      v-if="
                        contentForm.base_plan_id > 0 &&
                          (this.tts_data.length ||
                            this.nodes_data.length ||
                            this.feature_data.length ||
                            this.asr_data.length)
                      "
                    >
                      <div
                        style="margin-left: 5px; padding-bottom: 5px; display: flex"
                      >
                        <div class="info">
                          <!-- eslint-disable-next-line -->
                          {{ __("Plan summary for selected base plan and all of its descendant base plans") }}
                        </div>
                      </div>
                      <div style="max-height: 50vh">
                        <el-row type="flex">
                          <el-col :span="7">
                            <transition
                              appear
                              @before-enter="beforeEnter"
                              @enter="enter"
                              @before-leave="beforeLeave"
                              :css="false"
                            >
                              <el-tree
                                :data="tts_data"
                                :props="defaultProps"
                                accordion
                              ></el-tree>
                            </transition>
                          </el-col>
                          <el-col :span="5">
                            <transition
                              appear
                              @before-enter="beforeEnter"
                              @enter="enter"
                              :css="false"
                            >
                              <el-tree
                                :data="nodes_data"
                                :props="defaultProps"
                                accordion
                              ></el-tree>
                            </transition>
                          </el-col>
                          <el-col :span="6">
                            <transition
                              appear
                              @before-enter="beforeEnter"
                              @enter="enter"
                              @leave="beforeLeave"
                              :css="false"
                            >
                              <el-tree
                                :data="feature_data"
                                :props="defaultProps"
                                accordion
                              >
                                <span
                                  class="custom-tree-node"
                                  slot-scope="{ node }"
                                >
                                  <span> {{ node.label }}</span>
                                </span>
                              </el-tree>
                            </transition>
                          </el-col>

                          <el-col :span="9">
                            <transition
                              appear
                              @before-enter="beforeEnter"
                              @enter="enter"
                              :css="false"
                            >
                              <el-tree
                                :data="asr_data"
                                :props="defaultProps"
                                accordion
                              ></el-tree>
                            </transition>
                          </el-col>
                        </el-row>
                        <el-row style="margin-top: 30px" type="flex"
                          ><el-col :span="12" :offset="6"></el-col
                        ></el-row>
                      </div>
                    </div>
                  </el-scrollbar>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item :label="__('Minimum Units')" prop="min_unit">
                    <el-input-number
                      v-model="contentForm.min_unit"
                      :min="1"
                      :max="5000"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" v-if="contentForm.plan_type">
                <el-col :span="16" :offset="4">
                  <el-form-item
                    :label="__('Price Per Unit')"
                    prop="price_per_unit"
                  >
                    <el-input-number
                      @change="updateBillable"
                      v-model="contentForm.price_per_unit"
                      :min="0"
                      :max="1000"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item :label="__('Is Billable')" prop="bp_status">
                    <el-checkbox
                      v-model="contentForm.is_billable"
                      :disabled="disableBillable"
                      >{{ __("Billable") }}</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>

              <div v-loading="isFetching">
                <div
                  v-if="
                    this.appFeatures &&
                      this.appFeatures.length > 0 &&
                      contentForm.plan_type !== ''
                  "
                  v-loading="featureLoading"
                >
                  <el-row type="flex">
                    <el-col :span="16" :offset="4">
                      <el-form-item
                        :label="__('Features')"
                        prop="features"
                        :required="contentForm.base_plan_id === null"
                      >
                        <el-select
                          filterable
                          v-model="contentForm.features"
                          default-first-option
                          style="width: 100%"
                          multiple
                          clearable
                        >
                          <el-option
                            v-for="feature in filteredFeatures"
                            :key="feature.feature_id"
                            :label="feature.feature_name"
                            :value="feature.feature_id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div
                  v-if="
                    this.nodeTypes &&
                      this.nodeTypes.length > 0 &&
                      (contentForm.plan_type === 'IVA' ||
                        contentForm.plan_type === 'DVA' ||
                        contentForm.plan_type === 'Biometrics')
                  "
                  v-loading="nodesLoading"
                >
                  <el-row type="flex">
                    <el-col :span="16" :offset="4">
                      <el-form-item
                        :label="__('Node Types')"
                        prop="nodes"
                        :required="contentForm.base_plan_id === null"
                      >
                        <el-select
                          filterable
                          default-first-option
                          v-model="contentForm.nodes"
                          style="width: 100%"
                          multiple
                          clearable
                        >
                          <el-option
                            v-for="node in this.nodeTypes"
                            :key="node.node_type"
                            :label="node.node_type"
                            :value="node.node_type"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div
                  v-if="
                    (contentForm.plan_type === 'IVA' &&
                      contentForm.base_plan_id === null) ||
                      (contentForm.base_plan_id > 0 &&
                        this.ttsPackages &&
                        this.ttsPackages.length > 0)
                  "
                  v-loading="ttsLoading"
                >
                  <el-row type="flex">
                    <el-col :span="16" :offset="4">
                      <el-form-item
                        :label="__('TTS Packages')"
                        prop="tts_packages"
                        :required="contentForm.base_plan_id === null"
                      >
                        <el-select
                          filterable
                          default-first-option
                          v-model="contentForm.tts_packages"
                          style="width: 100%"
                          multiple
                          clearable
                        >
                          <el-option
                            v-for="ttsPack in this.ttsPackages"
                            :key="ttsPack.tts_package_id"
                            :label="ttsPack.tts_package_name"
                            :value="ttsPack.tts_package_id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div
                  v-if="
                    ((contentForm.plan_type === 'IVA' ||
                      contentForm.plan_type === 'DVA') &&
                      contentForm.base_plan_id === null) ||
                      (contentForm.base_plan_id > 0 &&
                        this.asrLanguagePackages &&
                        this.asrLanguagePackages.length > 0)
                  "
                  v-loading="asrLoading"
                >
                  <el-row type="flex">
                    <el-col :span="16" :offset="4">
                      <el-form-item
                        :label="__('ASR Packages')"
                        prop="asr_packages"
                        :required="contentForm.base_plan_id === null"
                      >
                        <el-select
                          filterable
                          default-first-option
                          v-model="contentForm.asr_packages"
                          style="width: 100%"
                          multiple
                          clearable
                        >
                          <el-option
                            v-for="asrPack in this.asrLanguagePackages"
                            :key="asrPack.asr_language_package_id"
                            :label="asrPack.asr_language_package_name"
                            :value="asrPack.asr_language_package_id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <el-row type="flex">
                <el-col :span="16" :offset="4">
                  <el-form-item :label="__('Is Active')" prop="bp_status">
                    <el-checkbox v-model="contentForm.bp_status">{{
                      __("Active")
                    }}</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-row type="flex">
              <el-col :span="16" :offset="4">
                <save-button
                  type="primary"
                  @click="createOrEditBusinessPlan"
                  class="submitBtn"
                  v-loading="isLoading"
                  v-show="this.canWrite()"
                  :primaryKey="id"
                  variant="CreateUpdate"
                />

                <el-button @click="handleCancelBtn" class="cancelBtn">{{
                  __("Cancel")
                }}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import gsap from "gsap";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PlanHierarchy from "@/views/billing/business-plans/pages/PlanHierarchy";
import PlanCompare from "@/views/billing/business-plans/pages/PlanCompare";
import PlanTree from "@/views/billing/business-plans/pages/PlanTree";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PlanCompare,
    PlanHierarchy,
    PageHeader,
    SaveButton,
    PlanTree
  },
  data() {
    const checkSelectedNodesTypes = (rule, value, callback) => {
      if (!this.selectedNodeTypes.length) {
        callback(__("Node types are required"));
      } else {
        callback();
      }
    };
    const checkSelectedFeatures = (rule, value, callback) => {
      if (!this.selectedFeatures.length) {
        callback(__("Features are required"));
      } else {
        callback();
      }
    };
    const checkSelectedVoices = (rule, value, callback) => {
      if (!this.selectedVoices.length) {
        callback(__("Voices are required"));
      } else {
        callback();
      }
    };
    return {
      commands: [
        {
          command: "Tree",
          rules: {
            plan_type: "iva"
          },
          icon: "icon-tree.svg",
          selectAction: false,
          label: __("Tree view")
        },
        {
          command: "Compare",
          rules: {
            plan_type: "iva"
          },
          icon: "icon-compare.svg",
          selectAction: false,
          label: __("Compare")
        },
        {
          command: "createOrEdit",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          selectAction: false
        },
        {
          command: "delete",
          rules: {},
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete"),
          selectAction: false
        }
      ],
      showCompare: false,
      showTree: false,
      asr_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      feature_data: [
        {
          label: __("Level one 1")
        }
      ],
      tts_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      nodes_data: [
        {
          label: __("Level one 1")
        }
      ],
      defaultProps: {
        children: "children",
        label: __("label")
      },
      plan_type: [
        "IVA",
        "QforMe Premium",
        "QforMe Standard",
        "Predictive Dialer",
        "Biometrics",
        "DVA"
      ],
      isFetching: false,
      businessPlanFormKey: 0,
      isReceiving: false,
      selectedNodeTypes: [],
      selectedFeatures: [],
      featureData: [],
      unit_name: "",
      selectedVoices: [],
      businessPlan: {},
      rules: {
        bp_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Business Plan Name is required.")
          }
        ],
        plan_type: [
          {
            required: true,
            trigger: "blur",
            message: __("Plan Type is required.")
          }
        ],
        min_unit: [
          {
            required: true,
            trigger: "blur",
            // eslint-disable-next-line
            message: __("Minimum Units are required. This would be numbers of session or waiter or dialer etc.")
          }
        ],
        price_per_unit: [
          {
            required: true,
            trigger: "blur",
            // eslint-disable-next-line
            message: __("Units Price is required. This would be price per session or waiter or dialer etc.")
          }
        ],
        node_types: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedNodesTypes
          }
        ],
        app_features: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedFeatures
          }
        ],
        tts_voices: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedVoices
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("businessplans", {
      isLoading: state => state.isLoading,
      safe_update: state => state.safe_update,
      basePlans: state => state.basePlans
    }),

    ...mapState("nodeTypes", {
      nodeTypes: state => state.nodeTypes,
      nodesLoading: state => state.isLoading
    }),

    ...mapState("appfeatures", {
      appFeatures: state => state.appFeatures,
      featureLoading: state => state.isLoading
    }),

    ...mapState("ttspackages", {
      ttsPackages: state => state.ttsPackages,
      ttsLoading: state => state.isLoading
    }),

    ...mapState("asrlanguagepack", {
      asrLanguagePackages: state => state.asrLanguagePackages,
      asrLoading: state => state.isLoading
    }),

    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentForm[key].toLowerCase());
        });
      });
    },

    //Used to filter biometrics feature based on biometric_type, otherwise use the default this.appFeatures list
    filteredFeatures() {
      if (this.contentForm.plan_type === "Biometrics") {
        if (this.contentForm.biometric_type === "Enrolment") {
          return this.appFeatures.filter(feature =>
            feature.feature_name.includes("ENROL")
          );
        }
        if (this.contentForm.biometric_type === "Verification") {
          return this.appFeatures
            .filter(feature => feature.feature_name.includes("VERIFY"))
            .map(feature => {
              if (feature.feature_name === "BIOMETRICS VERIFY") {
                return { ...feature, feature_name: "BIOMETRICS TRANSACTION" };
              }
              return feature;
            });
        }

        return this.appFeatures;
      } else {
        return this.appFeatures;
      }
    },

    isProduction() {
      return (
        process.env.VUE_APP_BACKEND_BASE_API.includes("us7") ||
        process.env.VUE_APP_BACKEND_BASE_API.includes("five9.net")
      );
    },

    disableBillable() {
      return this.contentForm.price_per_unit === 0;
    },

    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  beforeRouteLeave(to, from, next) {
    this.businessPlan = {};
    this.selectedNodeTypes = [];
    this.selectedFeatures = [];
    this.selectedVoices = [];
    next();
  },

  async mounted() {
    /* this.businessPlanFormKey += 1;
    this.businessPlan = _.cloneDeep(this.businessPlanToEdit);
    this.isReceiving = true;
    await this.initializeNodeTypes();
    await this.initializeAppFeatures();
    await this.initializeTtsVoices();
    await this.setNodeTypesOnUpdate();
    await this.setFeaturesOnUpdate();
    await this.setVoicesOnUpdate();
    this.isReceiving = false;*/
  },

  async created() {
    if (this.id !== null && !this.businessPlan.bp_id) {
      //await this.$router.push({ name: "business-plans" });
    }
    if (this.id === null) {
      /*this.$set(this.businessPlanToEdit, "bp_allowed_nodes", {});
      this.$set(this.businessPlanToEdit.bp_allowed_nodes, "data", {});
      this.$set(this.businessPlanToEdit, "bp_features", {});
      this.$set(this.businessPlanToEdit.bp_features, "data", {});
      this.$set(this.businessPlanToEdit, "bp_voices", {});
      this.$set(this.businessPlanToEdit.bp_voices, "data", {});*/
    }
  },

  methods: {
    ...mapActions("businessplans", {
      createBusinessPlan: "createBusinessPlan",
      updateBusinessPlan: "updateBusinessPlan",
      deleteContentMethod: "deleteBusinessPlan",
      undoDeleteContent: "undoDeleteBusinessPlan",
      checkBusinessPlanChanges: "checkBusinessPlanChanges",
      resetSafeUpdate: "resetSafeUpdate",
      getAvailableBasePlans: "getAvailableBasePlans",
      getRelationshipsForSelectedBasePlan:
        "getRelationshipsForSelectedBasePlan",
      getBpRelationsForEdit: "getBpRelationsForEdit",
      resetPlanComparison: "resetPlanComparison"
    }),
    ...mapActions("nodeTypes", {
      getNodeTypes: "getNodeTypes",
      setNodeTypesExcludingBasePlanNodes: "setNodeTypesExcludingBasePlanNodes",
      resetNodeTypesAsPerPlanType: "resetNodeTypesAsPerPlanType"
    }),
    ...mapActions("appfeatures", {
      getAppFeatures: "getAppFeatures",
      setFeaturesExcludingBasePlanFeatures:
        "setFeaturesExcludingBasePlanFeatures",
      resetFeaturesAsPerPlanType: "resetFeaturesAsPerPlanType"
    }),
    ...mapActions("ttspackages", {
      getTtsPackages: "getTtsPackages",
      setTTSPackagesExcludingBasePlanPackages:
        "setTTSPackagesExcludingBasePlanPackages",
      resetTTSPacksAsPerPlanType: "resetTTSPacksAsPerPlanType"
    }),
    ...mapActions("asrlanguagepack", {
      getAsrLanguagePackages: "getAsrLanguagePackages",
      setASRPackagesExcludingBasePlanPackages:
        "setASRPackagesExcludingBasePlanPackages",
      resetASRPacksAsPerPlanType: "resetASRPacksAsPerPlanType"
    }),
    ...mapActions("app", {
      getEnabledFeaturesForUser: "getEnabledFeaturesForUser"
    }),
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.resetSafeUpdate();
      this.handleCancel();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancelForm() {
      this.$router
        .push({
          name: "business-plans"
        })
        .catch(() => {});
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "scale(0,0)";
    },

    beforeLeave(el, done) {
      gsap.to(el, {
        duration: 0.6,
        opacity: 0,
        scale: 0,
        //ease: "circ.in",
        ease: "power1",
        onComplete: done
      });
    },

    enter(el, done) {
      gsap.to(el, {
        duration: 1,
        opacity: 1,
        scale: 1,
        ease: "circ.out",
        onComplete: done
      });
    },

    async clearBasePlan() {
      this.contentForm.base_plan_id = null;
      await this.resetFeaturesAsPerPlanType();
      await this.resetNodeTypesAsPerPlanType();
      await this.resetTTSPacksAsPerPlanType();
      await this.resetASRPacksAsPerPlanType();
      await this.resetContentChildren();
    },

    async resetContentChildren() {
      this.contentForm.features = _.flatMap(
        _.map(this.appFeatures, "feature_id")
      );
      this.contentForm.nodes = _.flatMap(_.map(this.nodeTypes, "node_type"));
    },

    async setContentChildrenToEmpty() {
      this.contentForm.features = [];
      this.contentForm.nodes = [];
      this.contentForm.tts_packages = [];
      this.contentForm.asr_packages = [];
      this.contentForm.biometric_type = null;
    },

    async setBasePlan(bp_id) {
      if (_.isNumber(bp_id)) {
        if (this.formAction !== "edit") {
          await this.setContentChildrenToEmpty();
          let plan_type = this.contentForm.plan_type;
          await this.getRelationshipsForSelectedBasePlan({ bp_id, plan_type });
        }
        let basePlan = _.find(this.basePlans, { bp_id: bp_id });
        this.feature_data =
          basePlan.features !== undefined ? basePlan.features : [];
        this.tts_data = basePlan.voices !== undefined ? basePlan.voices : [];
        this.asr_data =
          basePlan.languages !== undefined ? basePlan.languages : [];
        this.nodes_data = basePlan.nodes !== undefined ? basePlan.nodes : [];

        await this.setFeaturesInput(bp_id);
        await this.setNodesInput(bp_id);
        await this.setTTSPackages(bp_id);
        await this.setAsrLanguagePacks(bp_id);
      }
    },

    async setFeaturesInput(base_plan_id = null) {
      if (base_plan_id !== null) {
        this.setFeaturesExcludingBasePlanFeatures(
          _.find(this.basePlans, { bp_id: base_plan_id }).features_not_in_plan
        );
      }
      if (this.formAction !== "edit") {
        let filteredFeatures = this.filterFeatures(this.appFeatures);
        this.contentForm.features = _.flatMap(
          _.map(filteredFeatures, "feature_id")
        );
      }
    },

    //Used to filter biometrics feature based on biometric_type, otherwise use the default this.appFeatures list
    filterFeatures(features) {
      if (this.contentForm.plan_type === "Biometrics") {
        if (this.contentForm.biometric_type === "Enrolment") {
          return features.filter(feature =>
            feature.feature_name.includes("ENROL")
          );
        }
        return features.filter(feature =>
          feature.feature_name.includes("VERIFY")
        );
      } else {
        return features;
      }
    },

    async setNodesInput(base_plan_id = null) {
      if (base_plan_id !== null) {
        this.setNodeTypesExcludingBasePlanNodes(
          _.find(this.basePlans, { bp_id: base_plan_id }).nodes_not_in_plan
        );
      }
      if (this.formAction !== "edit") {
        this.contentForm.nodes = _.flatMap(_.map(this.nodeTypes, "node_type"));
      }
    },

    async setTTSPackages(base_plan_id = null) {
      if (base_plan_id !== null) {
        this.setTTSPackagesExcludingBasePlanPackages(
          _.find(this.basePlans, { bp_id: base_plan_id })
            .tts_packages_not_in_plan
        );
      }
    },

    async setAsrLanguagePacks(base_plan_id = null) {
      if (base_plan_id !== null) {
        this.setASRPackagesExcludingBasePlanPackages(
          _.find(this.basePlans, { bp_id: base_plan_id })
            .language_packs_not_in_plan
        );
      }
    },

    async setEditPlan(base_plan_id, plan_type, bp_id, is_in_use_as_base) {
      await this.getAvailableBasePlans({
        base_plan_id,
        plan_type,
        bp_id,
        is_in_use_as_base
      });
      await this.setBasePlan(base_plan_id);
    },

    async setEditModeForAPlanWithNoBasePlanButIsBeingUsedAsBasePlan(
      bp_id,
      plan_type,
      is_in_use_as_base
    ) {
      let response = await this.getBpRelationsForEdit({
        bp_id,
        plan_type,
        is_in_use_as_base
      });

      this.setFeaturesExcludingBasePlanFeatures(
        response.data.features_not_in_plan
      );
      this.setNodeTypesExcludingBasePlanNodes(response.data.nodes_not_in_plan);
      this.setTTSPackagesExcludingBasePlanPackages(
        response.data.tts_packages_not_in_plan
      );
      this.setASRPackagesExcludingBasePlanPackages(
        response.data.language_packs_not_in_plan
      );
    },

    async loadFeatures(plan_type) {
      this.isFetching = true;
      await this.getAppFeatures({ plan_type });
      if (!this.contentForm.biometric_type || plan_type !== "Biometrics") {
        this.$set(this.contentForm, "biometric_type", null);
      }
      if (plan_type === "IVA") {
        await this.getAvailableBasePlans({ plan_type });
        await this.getNodeTypes({ plan_type });
        await this.setFeaturesInput();
        await this.setNodesInput();
        await this.getTtsPackages();
        await this.getAsrLanguagePackages();
      } else if (plan_type === "DVA" || plan_type === "Biometrics") {
        let filteredFeatures = this.filterFeatures(this.appFeatures);
        this.contentForm.features = _.flatMap(
          _.map(filteredFeatures, "feature_id")
        );
        await this.getNodeTypes({ plan_type });
        this.contentForm.nodes = _.flatMap(_.map(this.nodeTypes, "node_type"));
        await this.setNodesInput();
        await this.setFeaturesInput();
        if (
          plan_type === "Biometrics" &&
          this.contentForm.biometric_type === null
        ) {
          this.$set(this.contentForm, "biometric_type", "Verification");
        }
      } else {
        this.contentForm.features = _.flatMap(
          _.map(this.appFeatures, "feature_id")
        );
        this.contentForm.nodes = [];
        this.contentForm.tts_packages = [];
        this.contentForm.asr_packages = [];
      }
      this.isFetching = false;
    },

    async submitFormData() {
      const process =
        this.id === -1 ? this.createBusinessPlan : this.updateBusinessPlan;
      process(this.contentForm)
        .then(data => {
          this.id === -1
            ? this.$message({
                name: "success",
                message: __("Business Plan added successfully")
              })
            : this.$message({
                type: "success",
                message: __("Business Plan updated successfully")
              });
          EventBus.$emit("list-changed", data.data);
          this.resetSafeUpdate();
          if (this.selectedAccountId !== "all") {
            this.getEnabledFeaturesForUser(this.selectedAccountId);
          }
          this.handleCancel();
        })
        .catch(err => {
          console.log(err);
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    async checkForMinimumInputIfABasePlanIsSet() {
      if (this.contentForm.plan_type === "IVA") {
        if (
          !this.contentForm.features.length &&
          !this.contentForm.nodes.length &&
          !this.contentForm.asr_packages.length &&
          !this.contentForm.tts_packages.length
        ) {
          this.$message({
            type: "error",
            // eslint-disable-next-line
            message: __("This plan does not qualify to have a base plan. There should be minimum one extra - feature or voice or node type or asr package.")
          });
        } else {
          await this.submitFormData();
        }
      }
    },

    async createOrEditBusinessPlan() {
      await this.$refs.contentForm.validate(async valid => {
        if (valid) {
          if (this.id !== -1) {
            await this.checkBusinessPlanChanges(this.contentForm);
          }
          if (this.safe_update === "success" || this.safe_update === true) {
            if (this.contentForm.base_plan_id !== null) {
              await this.checkForMinimumInputIfABasePlanIsSet();
            } else {
              await this.submitFormData();
            }
          } else {
            this.$confirm(
              this.safe_update +
                ". " +
                __("This may break their task execution. continue?"),
              __("Warning"),
              {
                confirmButtonText: __("OK"),
                cancelButtonText: __("Cancel"),
                type: "warning"
              }
            ).then(() => {
              this.submitFormData();
            });
          }
        }
      });
    },

    updateBillable() {
      if (this.contentForm.price_per_unit === 0) {
        this.contentForm.is_billable = false;
      }
    },

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAction(action) {
      if (action === "delete") {
        this.handleDelete(this.contentForm);
      } else if (action === "createOrEdit") {
        this.handleEdit();
      } else if (action === "Tree") {
        this.handleTreeView();
      } else {
        this.handleCompare();
      }
    },

    handleTreeView() {
      this.showTree = true;
    },

    handleTreeViewClose() {
      this.showTree = false;
    },

    handleCompare() {
      this.showCompare = true;
    },

    handleCompareClose() {
      this.showCompare = false;
      this.resetPlanComparison();
    }

    /*bp_allowed_nodes(nodes) {
      return Object.entries(nodes).map(function(item) {
        return {
          node_type: item[1]
        };
      });
    },

    bp_features(features) {
      return Object.entries(features).map(function(item) {
        return {
          feature_id: item[1]
        };
      });
    },

    bp_voices(voices) {
      return Object.entries(voices).map(function(item) {
        return {
          tts_voice_id: item[1]
        };
      });
    },*/

    /*async initializeNodeTypes() {
      if (!this.nodeTypes.length) {
        await this.getNodeTypes();
      }
    },

    async initializeAppFeatures() {
      if (!this.appFeatures.length) {
        await this.getAppFeatures();
        this.featureData = this.appFeatures;
      }
    },

    async initializeTtsVoices() {
      if (!this.ttsVoices.length) {
        await this.getTTSVoices();
      }
    },

    async setNodeTypesOnUpdate() {
      if (this.businessPlan.bp_allowed_nodes.data) {
        this.selectedNodeTypes = _.map(
          this.businessPlan.bp_allowed_nodes.data,
          function(item) {
            return item.node_type;
          }
        );
      } else {
        this.selectedNodeTypes = _.map(
          this.businessPlan.bp_allowed_nodes,
          function(item) {
            return item.node_type;
          }
        );
      }
    },

    async setFeaturesOnUpdate() {
      if (this.businessPlan.bp_features.data) {
        this.selectedFeatures = _.map(
          this.businessPlan.bp_features.data,
          function(item) {
            return item.feature_id;
          }
        );
      } else {
        this.selectedFeatures = _.map(this.businessPlan.bp_features, function(
          item
        ) {
          return item.feature_id;
        });
      }
      await this.changeFeatureList(this.selectedFeatures);
    },

    async setVoicesOnUpdate() {
      if (this.businessPlan.bp_voices.data) {
        this.selectedVoices = _.map(this.businessPlan.bp_voices.data, function(
          item
        ) {
          return item.tts_voice_id;
        });
      } else {
        this.selectedVoices = _.map(this.businessPlan.bp_voices, function(
          item
        ) {
          return item.tts_voice_id;
        });
      }
    },

    async changeFeatureList(feature_ids) {
      let feature_id = feature_ids[0];
      if (feature_id) {
        let featureDataFlatMap = _.flatMap(this.featureData);
        let feature = _.find(featureDataFlatMap, {
          feature_id: feature_id
        });
        console.log(feature);
        for (let i = 0; i < featureDataFlatMap.length; i++) {
          if (
            featureDataFlatMap[i].feature_unit_name !==
            feature.feature_unit_name
          ) {
            featureDataFlatMap[i].disabled = true;
          }
        }
        this.unit_name = feature.feature_unit_name;
      } else {
        let featureDataFlatMap = _.flatMap(this.featureData);
        for (let i = 0; i < featureDataFlatMap.length; i++) {
          featureDataFlatMap[i].disabled = false;
        }
        this.unit_name = "";
      }
    }*/

    /*async disableOrEnableFeatures(feature_ids) {
       /!* if (feature_ids.length) {
          for (let i = 0; i < feature_ids.length; i++) {
            let feature_id = parseInt(feature_ids[i]);
            let feature = _.find(this.featuresList, {
              feature_id: feature_id
            });

            for (let i = 0; i < feature.excluded_features.length; i++) {
              let disabledFeature = _.find(this.featuresList, {
                feature_name: feature.excluded_features[i]
              });
              disabledFeature.disabled = true;
            }
          }
        } else {
          for (let i = 0; i < this.featuresList.length; i++) {
            this.featuresList[i].disabled = false;
          }
        }*!/
      }*/
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val === "edit") {
          if (
            this.contentFormInfo.base_plan_id !== null &&
            !this.contentFormInfo.is_in_use_as_base
          ) {
            this.setEditPlan(
              this.contentFormInfo.base_plan_id,
              this.contentFormInfo.plan_type,
              this.contentFormInfo.bp_id,
              this.contentFormInfo.is_in_use_as_base
            );
          } else if (
            this.contentFormInfo.base_plan_id !== null &&
            this.contentFormInfo.is_in_use_as_base
          ) {
            this.setEditPlan(
              this.contentFormInfo.base_plan_id,
              this.contentFormInfo.plan_type,
              this.contentFormInfo.bp_id,
              this.contentFormInfo.is_in_use_as_base
            );
          } else if (
            this.contentFormInfo.base_plan_id === null &&
            this.contentFormInfo.is_in_use_as_base
          ) {
            // do somethng
            this.setEditModeForAPlanWithNoBasePlanButIsBeingUsedAsBasePlan(
              this.contentForm.bp_id,
              this.contentFormInfo.plan_type,
              this.contentFormInfo.is_in_use_as_base
            );
          } else {
            this.getAppFeatures({
              plan_type: this.contentFormInfo.plan_type
            });
            this.getNodeTypes({ plan_type: this.contentFormInfo.plan_type });
            this.getTtsPackages();
            this.getAsrLanguagePackages();
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .plan-form-dialog {
  max-height: 90vh !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }

  .el-select__tags {
    ::v-deep .el-tag {
      background-color: $content-theme-color !important;
      border-color: $content-theme-color !important;
      .el-tag__close {
        background-color: $content-theme-color !important;
      }
    }
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
</style>
