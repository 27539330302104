<template>
  <div>
    <el-row type="flex">
      <el-col :span="24" :offset="3">
        <PageHeader
          style="margin-top: -15px"
          :title="contentFormInfo.bp_name"
        ></PageHeader>
      </el-col>
    </el-row>
    <el-scrollbar :native="false">
      <div style="max-height: 75vh">
        <el-form>
          <el-row type="flex">
            <el-col :span="24" :offset="3">
              <el-form-item :label="__('Compare with')">
                <el-col :span="16">
                  <el-select
                    v-model="compare_plan_id"
                    style="width: 100%"
                    default-first-option
                    filterable
                  >
                    <el-option
                      v-for="plan in this.businessPlans"
                      v-show="
                        plan.bp_id !== contentFormInfo.bp_id &&
                          plan.plan_type === 'IVA'
                      "
                      :key="plan.bp_id"
                      :label="plan.bp_name"
                      :value="plan.bp_id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" :offset="1">
                  <el-button @click="showComparison" v-loading="isLoading">{{
                    __("preview difference")
                  }}</el-button>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="
              compare_plan_id > 0 && plan_comparison.length > 0 && !isLoading
            "
          >
            <el-row type="flex">
              <el-col :span="24" :offset="3">
                <div style="margin-top: 5px" v-if="left_diff">
                  <span
                    >{{
                      __(":bp_name has more items listed as following", {
                        bpname: contentFormInfo.bp_name
                      })
                    }}
                  </span>
                </div>
                <el-scrollbar :native="false">
                  <div style="max-height: 20vh" v-if="left_diff">
                    <ul style="padding-left: 25px">
                      <li v-for="item in left_diff" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </el-scrollbar>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" :offset="3">
                <div style="margin-top: 5px" v-if="right_diff">
                  <span>
                    <!-- eslint-disable-next-line -->
                    {{ __(":compare_plan_name has more items listed as following", {
                          compare_plan_name: this.compare_plan_name
                        }
                      )
                    }}
                  </span>
                </div>
                <el-scrollbar :native="false">
                  <div style="max-height: 20vh">
                    <ul style="padding-left: 25px">
                      <li v-for="item in right_diff" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </el-scrollbar>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" :offset="3">
                <el-input
                  :placeholder="__('Search Item')"
                  suffix-icon="el-icon-search"
                  style="width: 30%; margin-top: 5px; padding-top: 5px; padding-bottom: 10px"
                  class="searchInput"
                  v-model="filterText"
                >
                </el-input>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" :offset="3">
                <el-col :span="10">
                  <el-scrollbar :native="false">
                    <div style="max-height: 33vh">
                      <span>
                        {{
                          __(":bp_name all items", {
                            bpname: contentFormInfo.bp_name
                          })
                        }}</span
                      >
                      <el-tree
                        :data="left_tts_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="ltts"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="left_nodes_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="lnodes"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="left_feature_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="lfeatures"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="left_asr_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="lasr"
                        accordion
                      ></el-tree>
                    </div>
                  </el-scrollbar>
                </el-col>
                <el-col :span="10" :offset="3">
                  <el-scrollbar :native="false">
                    <div style="max-height: 33vh">
                      <span>
                        {{
                          __(":plan_name all items", {
                            plan_name: this.compare_plan_name
                          })
                        }}
                      </span>
                      <el-tree
                        :data="right_tts_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="rtts"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="right_nodes_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="rnodes"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="right_feature_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="rfeatures"
                        accordion
                      ></el-tree>
                      <el-tree
                        :data="right_asr_data"
                        :props="defaultProps"
                        :filter-node-method="filterNode"
                        ref="rasr"
                        accordion
                      ></el-tree>
                    </div>
                  </el-scrollbar>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader
  },
  props: {
    contentFormInfo: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState("businessplans", {
      businessPlans: state => state.businessPlans,
      isLoading: state => state.isLoading,
      plan_comparison: state => state.plan_comparison
    })
  },

  data() {
    return {
      compare_plan_id: "",
      filterText: "",
      left_asr_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      right_asr_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      left_feature_data: [
        {
          label: __("Level one 1")
        }
      ],
      right_feature_data: [
        {
          label: __("Level one 1")
        }
      ],
      left_tts_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      right_tts_data: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      left_nodes_data: [
        {
          label: __("Level one 1")
        }
      ],
      right_nodes_data: [
        {
          label: __("Level one 1")
        }
      ],
      left_diff: [],
      right_diff: [
        {
          label: __("Level one 1"),
          children: [
            {
              label: __("child 1"),
              children: [
                {
                  label: __("child 2")
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: "children",
        label: "label"
      },
      compare_plan_name: ""
    };
  },

  methods: {
    ...mapActions("businessplans", {
      comparePlans: "comparePlans"
    }),
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value) !== -1;
    },
    async showComparison() {
      this.compare_plan_name = _.find(this.businessPlans, {
        bp_id: this.compare_plan_id
      }).bp_name;
      await this.comparePlans({
        bp_id: this.contentFormInfo.bp_id,
        compare_plan_id: this.compare_plan_id
      });
      if (this.plan_comparison.length > 0) {
        let comparison = this.plan_comparison[0];
        this.left_asr_data = comparison.left.languages;
        this.left_feature_data = comparison.left.features;
        this.left_tts_data = comparison.left.voices;
        this.left_nodes_data = comparison.left.nodes;
        this.left_diff = comparison.left.difference;

        this.right_asr_data = comparison.right.languages;
        this.right_feature_data = comparison.right.features;
        this.right_tts_data = comparison.right.voices;
        this.right_nodes_data = comparison.right.nodes;
        this.right_diff = comparison.right.difference;
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.lasr.filter(val);
      this.$refs.lfeatures.filter(val);
      this.$refs.lnodes.filter(val);
      this.$refs.ltts.filter(val);
      this.$refs.rasr.filter(val);
      this.$refs.rfeatures.filter(val);
      this.$refs.rnodes.filter(val);
      this.$refs.rtts.filter(val);
    }
  }
};
</script>
<style scoped lang="scss">
.chart {
  line-height: 0;
}
.highcharts-container {
  height: 800px !important;
}
::v-deep .searchInput {
  input {
    height: 36px !important;
    line-height: 36px !important;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }
}
</style>
